"use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function init() {// JavaScript to be fired on all pages
      },
      finalize: function finalize() {
        // Lock form input for locked user
        var userID = jQuery("input[name=userID]").val();

        if (userID !== "") {
          $("input[type='text']").each(function (i, v) {
            var elem = $(elem);
            if (elem.attr('type') && elem.val() != "") elem.prop("disabled", true);
          });
        } // Period calendar


        var dateStart = $("input[name=date-start]");
        if (dateStart) dateStart.datepicker("option", {
          onClose: function onClose(selectedDate) {
            $("input[name=date-end]").datepicker("option", "minDate", selectedDate);
          }
        });
        var dateStop = $("input[name=date-end]");
        if (dateStop) dateStop.datepicker("option", {
          onClose: function onClose(selectedDate) {
            $("input[name=date-start]").datepicker("option", "maxDate", selectedDate);
          }
        });
        var divAgenda = $("#agenda");
        if (divAgenda) divAgenda.tabs({
          beforeLoad: function beforeLoad(event, ui) {
            ui.jqXHR.fail(function () {
              ui.panel.html();
            });
          }
        });
        var divPortfolio = $("#portfolio");
        if (divPortfolio) divPortfolio.tabs({
          beforeLoad: function beforeLoad(event, ui) {
            ui.jqXHR.fail(function () {
              ui.panel.html();
            });
          }
        }); // Activer / Désactiver l'accessibilité

        $('.accessibility-btn').click(function () {
          $('html').toggleClass('accessibility');
        });
      }
    },
    // Home page
    'home': {
      init: function init() {// JavaScript to be fired on the home page
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function init() {// JavaScript to be fired on the about us page
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) namespace[func][funcname](args);
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire('common'); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      }); // Fire common finalize JS

      UTIL.fire('common', 'finalize');
    }
  }; // Load Events

  $(document).ready(UTIL.loadEvents);
  $(window).load(function () {
    /*----------
        TYPO
    ----------*/
    $('h1,h2,h3,h4,h5,h6,footer .left-top p').each(function () {
      var str = $(this).text(),
          letters = '';

      for (var i = 0, len = str.length; i < len; i++) {
        if (str[i].match(/[aqAQàÀ]/i)) {
          letters += '<span class="font-alt">' + str[i] + '</span>';
        } else {
          letters += str[i];
        }
      }

      $(this).html(letters);
    });
    /*----------
        MOBILE MENU
    ----------*/

    $('.mobile-menu-toggle, .mobile-menu-close').click(function () {
      $('.mobile-menu').toggleClass('active');
    });
    /*----------
        SEARCH
    ----------*/

    $('.toggle-search, .close-search').click(function () {
      $('.search').toggleClass('active');
    });
    /*----------
        HOMETOP
    ----------*/

    var dots = $('.hometop .item').length;

    if (dots > 1) {
      for (i = 1; i <= dots; i++) {
        $('.hometop .dots').append('<span class="dot" id="e-' + i + '"></span>');
      }

      $('.hometop .dots > .dot:first-child').addClass('active');
      $('.hometop .prev').click(function () {
        var e = $('.hometop').find('.item.active');
        var prev = e.prev('.item');
        var edot = $('.hometop .dot.active');
        var prevdot = edot.prev('.dot');

        if (prev.length) {
          e.removeClass('active');
          prev.addClass('active');
          edot.removeClass('active');
          prevdot.addClass('active');
        } else {
          e.removeClass('active');
          console.log($('.hometop .item').last());
          $('.hometop .item').last().addClass('active');
        }
      });
      $('.hometop .next').click(function () {
        var e = $('.hometop').find('.item.active');
        var next = e.next('.item');
        var edot = $('.hometop .dot.active');
        var nextdot = edot.next('.dot');

        if (next.length) {
          e.removeClass('active');
          next.addClass('active');
          edot.removeClass('active');
          nextdot.addClass('active');
        } else {
          e.removeClass('active');
          $('.hometop .item').first().addClass('active');
        }
      });
      $('.hometop .dot').click(function () {
        var e = $(this).attr('id');
        $('.hometop .item .dot.active').removeClass('active');
        $('.hometop .dot#' + e).addClass('active');
        $('.hometop .item.active').removeClass('active');
        $('.hometop .item.' + e).addClass('active');
      });
    } else {
      $('.hometop .dots').css('display', 'none');
      $('.hometop .nav').css('display', 'none');
    }
    /*----------
        MENU PARTENAIRE
    ----------*/


    if ($("body").hasClass("espace-partenaire") || $("body").hasClass("espace-ambassadeur")) {
      $('.partenaire-nav-toggle').toggleClass('active');
    }

    $('.partenaire-nav-toggle, .partenaire-close').click(function () {
      $('.partenaire-nav-wrapper').toggleClass('active');
    });
    /*----------
        HOMETOP
    ----------*/

    $('.hometop .prev').click(function () {
      var e = $('.hometop .item.active');
      var prev = e.prev('.item');

      if (prev.length) {
        e.removeClass('active');
        prev.addClass('active');
      }
    });
    $('.hometop .next').click(function () {
      var e = $('.hometop .item.active');
      var next = e.next('.item');

      if (next.length) {
        e.removeClass('active');
        next.addClass('active');
      }
    });
    /*----------
        IMGTEXT
    ----------*/

    $('.imgtext .next, .imgtext .prev').click(function () {
      $('.imgtext .overlay').toggleClass('active');
    });
    /*----------
        CAROUSEL
    ----------*/

    $('.carousel-three').owlCarousel({
      loop: true,
      margin: 15,
      nav: true,
      autoplay: true,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        }
      }
    });

    if ($('.homeslide').length) {
      new Glide('.homeslide', {
        type: 'carousel',
        perView: 1,
        focusAt: 'center'
      }).mount();
    }

    if ($('.postslide').length) {
      new Glide('.postslide', {
        type: 'carousel',
        perView: 3,
        focusAt: 'center',
        autoplay: 5000,
        breakpoints: {
          500: {
            perView: 1
          }
        }
      }).mount();
    }

    if ($('.testimonialslide').length) {
      new Glide('.testimonialslide', {
        type: 'carousel',
        perView: 3,
        focusAt: 'center',
        autoplay: 3000,
        breakpoints: {
          800: {
            perView: 2
          },
          500: {
            perView: 1
          }
        }
      }).mount();
    }

    if ($('.agendaslide-11').length) {
      new Glide('.agendaslide-11', {
        type: 'carousel',
        perView: 4,
        gap: 0,
        focusAt: 1,
        autoplay: 4000,
        breakpoints: {
          500: {
            perView: 1
          },
          700: {
            perView: 2
          },
          1100: {
            perView: 3
          }
        }
      }).mount();
    }

    if ($('.agendaslide-12').length) {
      new Glide('.agendaslide-12', {
        type: 'carousel',
        perView: 4,
        gap: 0,
        focusAt: 1,
        autoplay: 4000,
        breakpoints: {
          500: {
            perView: 1
          },
          700: {
            perView: 2
          },
          1100: {
            perView: 3
          }
        }
      }).mount();
    }

    if ($('.agendaslide-13').length) {
      new Glide('.agendaslide-13', {
        type: 'carousel',
        perView: 4,
        gap: 0,
        focusAt: 1,
        autoplay: 4000,
        breakpoints: {
          500: {
            perView: 1
          },
          700: {
            perView: 2
          },
          1100: {
            perView: 3
          }
        }
      }).mount();
    }

    if ($('.numberslide').length) {
      if (window.matchMedia("(max-width: 850px)").matches) {
        new Glide('.numberslide', {
          type: 'carousel',
          perView: 1,
          focusAt: 'center',
          autoplay: 3000
        }).mount();
      } else {
        new Glide('.numberslide', {
          type: 'carousel',
          perView: 1,
          gap: 110,
          peek: 350,
          focusAt: 'center',
          autoplay: 3000
        }).mount();
      }
    }
    /*----------
        SCROLL TOP
    ----------*/


    $(window).scroll(function () {
      if ($(window).scrollTop() > 100) {
        $('.scrolltop').addClass('active');
      } else {
        $('.scrolltop').removeClass('active');
      }
    });
    $('.scrolltop').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1000);
    });
    /*----------
        PORTFOLIO
    ----------*/

    $('.portfolio .do, .portfolio .undo').click(function () {
      var element = $(this).closest('.box');
      element.toggleClass('active');
    });
    $(".playpause").click(function () {
      if ($(this).prev("video").get(0).paused) {
        $(this).prev("video").get(0).play();
        $(this).prev("video").get(0).requestFullscreen();
        $(this).css('opacity', '0');
      } else {
        $(this).prev("video").get(0).pause();
        $(this).css('opacity', '1');
      }
    });
  });
})(jQuery); // Fully reference jQuery after this point.